import React from 'react';
import Layout from '../components/Layout';
import TitleAndTheme from '../components/TitleAndTheme';
import ProjectHeader from '../components/ProjectHeader';
import Grid from '../components/Grid';
import AssetWithCaption from '../components/AssetWithCaption';
import Text from '../components/Text';
import Code from '../components/Code';

const OrigamiHue = () => (
  <Layout>
    <TitleAndTheme
      title="Edwin Morris, Origami Hue Controller personal project"
      themeClass="theme--origamihue"
    />

    <ProjectHeader
      name="Origami Hue Controller"
      subhead="Prototyping a real app"
      sectionid="origamihue"
    />

    <Text>
      <p>
        I used Origami Studio, a prototyping tool, to create a working Phillips
        Hue controller.
      </p>
      <p>
        Many design tools like Sketch and Framer X now include the ability to
        use live data from APIs. In May 2018, Origami Studio was updated to
        include a “network request” feature that allows prototypes to make POST
        and GET requests. Beyond simply reading data, prototypes can now create
        and update data in all the same ways as a real app. Combined with
        Origami Live, which allows prototypes to run independently on iOS, it’s
        possible to create a fully functional app without writing a line of
        code.
      </p>
      <p>
        The Hue API is particularly fun for experiments because it controls
        real-life objects.
      </p>
    </Text>

    <Grid>
      <AssetWithCaption
        position="full"
        video="origamihue/origamihue_demo_720p"
        width="1280"
        height="720"
      >
        Turning my apartment lights on and off with a standalone prototype.
      </AssetWithCaption>
    </Grid>

    <Text>
      <p>
        To turn a light on or off, the Hue API requires a PUT request with an
        access token in the request header. Origami Studio doesn’t support PUTs
        or headers, so I created a proxy server to format and forward its
        requests. The prototype sends a POST request to the proxy, and includes
        an options object in the request body. The proxy parses and transforms
        the options object, makes a request to the Hue API, and sends the
        response back to the prototype.
      </p>
    </Text>

    <Grid>
      <Code
        position="left_half"
        code={`{
  "body" : { "bri" : 0, "on" : 0 },
  "json" : 1,
  "headers" : {
    "Authorization" : "Bearer <access-token>",
    "Content-Type" : "application/json"
  },
  "method" : "PUT",
  "uri" : "https://api.meethue.com/bridge/<whitelist-id>/lights/1/state"
}`}
      >
        The POST request body.
      </Code>
      <Code
        position="right_half"
        code={`app.post('/', ({body: requestBody}, proxyResponse) => {
  const data = parseBody(requestBody);
  const transformedData = transformBools(data, ['body.on', 'json']);

  requestPromise(transformedData).then((response) => {
    proxyResponse.send(response);
  }).catch((error) => {
    proxyResponse.send(error);
  });
});`}
      >
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://github.com/ehmorris/origami-hue-example-app/blob/master/index.js#L31-L40"
        >
          Some of the proxy parsing code.
        </a>
      </Code>
    </Grid>

    <Text noBottomMargin>
      <p>
        Support for more request types is likely to land in Origami Studio soon.
        Many more ideas are possible with network requests: a search page that
        returns real results, a working chat app, an Apple TV remote, and so on.
      </p>
      <p>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://github.com/ehmorris/origami-hue-example-app"
        >
          The code and working files for this project are on my GitHub
        </a>
        .
      </p>
    </Text>
  </Layout>
);

export default OrigamiHue;
