import React from 'react';
import styled from 'styled-components';
import AssetCaption from './AssetCaption';
import AssetPosition from './AssetPosition';
import { minColWidth } from '../utils/gridWidths';

const Code = ({ position, code, children }) => (
  <AssetPosition position={position}>
    <CodeContainer>
      <code>{code}</code>
    </CodeContainer>

    {children && <AssetCaption position={position}>{children}</AssetCaption>}
  </AssetPosition>
);

export default Code;

const CodeContainer = styled.pre`
  border: 1px solid var(--theme-primary);
  box-sizing: border-box;
  font-size: 18px;
  height: 100%;
  margin: 0;
  overflow-x: scroll;
  padding: 1em;
  -webkit-overflow-scrolling: touch;
  width: 100%;

  @media screen and (max-width: ${`${32 * minColWidth}px`}) {
    margin: 0 1em;
    width: calc(100% - 2em);
  }

  code {
    word-break: normal;
    word-wrap: normal;
  }
`;
